import { Component, useEffect, Fragment } from "react";
import { useOutletContext } from "react-router-dom";
import Papa from "papaparse";

export default function Wtcm() {
  const [user, setUser] = useOutletContext();
  useEffect(() => {
    document.getElementById("title").innerText = "WT Custom Message Tool";
  }, []);
  return <WtcmPage user={user} setUser={setUser} />;
}

const MAX_FILE_SIZE = 1024 * 1024 * 5; //5MB

class WtcmPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csvHeader: [],
      csvData: [],
      filteredCsv: [],
      selectedLang: "",
      searchQuery: "",
    };
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.filterCsv = this.filterCsv.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);
  }

  handleFileUpload(e) {
    const file = e.target.files[0];
    if (!file) return;
    if (file.type !== "text/csv" && !file.name.endsWith(".csv")) {
      alert(
        "Invalid file type. Please select the menu.csv file generated by your War Thunder client."
      );
      return;
    }
    if (file.size > MAX_FILE_SIZE) {
      alert(`File too large. Max size is ${MAX_FILE_SIZE / 1024 / 1024}MB`);
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      if (!text.includes(";") || text.split("\n").length < 2) {
        alert("Invalid CSV format.");
        return;
      }
      Papa.parse(text, {
        complete: (result) => {
          const [headerRow, ...dataRows] = result.data;
          let data = dataRows.map((row) => row.map((cell) => cell || ""));
          this.setState({
            csvHeader: headerRow,
            csvData: data,
            filteredCsv: data,
            selectedLang: headerRow[1],
          });
        },
        skipEmptyLines: true,
        delimiter: ";",
      });
    };

    reader.readAsText(file);
  }

  filterCsv() {
    const { csvData, searchQuery } = this.state;
    this.setState({
      filteredCsv: csvData.filter((row) =>
        row[0]?.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    });
  }

  downloadCSV() {
    const { csvHeader, csvData } = this.state;
    const csvString = Papa.unparse([csvHeader, ...csvData], { delimiter: ";" });
    const blob = new Blob([csvString], { type: "text/csv" });
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = "menu.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  render() {
    const { handleFileUpload, downloadCSV } = this;
    const { csvData, csvHeader, filteredCsv, selectedLang, searchQuery } =
      this.state;
    let inputTimeout = false;

    return (
      <Fragment>
        <h2>War Thunder Custom Message Tool</h2>
        <div className="wtcmFilterContainer">
          <input
            className="wtcmFileInput"
            type="file"
            accept=".csv"
            onChange={handleFileUpload}
          />
          {csvData.length > 0 && (
            <Fragment>
              {csvHeader.length > 1 && (
                <div>
                  <label>Select Language: </label>
                  <select
                    value={selectedLang}
                    onChange={(e) => {
                      this.setState({ selectedLang: e.target.value });
                    }}
                  >
                    {csvHeader.slice(1).map((header, index) => (
                      <option key={index} value={header}>
                        {header}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div>
                <label>Search Message ID: </label>
                <input
                  type="text"
                  value={searchQuery}
                  disabled={csvData.length === 0}
                  onChange={(e) => {
                    if (inputTimeout) clearTimeout(inputTimeout);
                    inputTimeout = setTimeout(this.filterCsv, 500);
                    this.setState({ searchQuery: e.target.value });
                  }}
                  placeholder="Search"
                />
              </div>
              <button onClick={downloadCSV}>Download Updated CSV</button>
            </Fragment>
          )}
        </div>

        {csvData.length > 0 && (
          <Fragment>
            <table className="wtcmTable" border="1">
              <thead>
                <tr>
                  <th>{csvHeader[0]}</th> {/* Message ID */}
                  <th>{selectedLang}</th> {/* Selected language column */}
                </tr>
              </thead>
              <tbody>
                {filteredCsv.length > 0 ? (
                  filteredCsv.map((row, rowIndex) => {
                    const langIndex = csvHeader.indexOf(selectedLang);
                    return (
                      <tr key={rowIndex}>
                        <td>{row[0]}</td> {/* Message ID (read-only) */}
                        <td>
                          <input
                            type="text"
                            value={row[langIndex]}
                            onChange={(e) => {
                              const updatedData = [...csvData];
                              updatedData[csvData.indexOf(row)][langIndex] =
                                e.target.value;
                              this.setState({ csvData: updatedData });
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="2">No matching results</td>
                  </tr>
                )}
              </tbody>
            </table>
          </Fragment>
        )}
      </Fragment>
    );
  }
}
